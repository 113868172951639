import React from 'react'
import Layout from '../components/Layout'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import '../styles/styles.scss'
import More from '../components/MoreBlog'

const blog = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulRichardWealeBlog(
        sort: { fields: publishedDate, order: DESC }
      ) {
        edges {
          node {
            title
            publishedDate
            snippet
            slug
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <div className="blog-main-wrapper">
        <div className="blog-section">
          <div className="blog-list">
            <ol>
              {data.allContentfulRichardWealeBlog.edges.map(edge => {
                return (
                  <li>
                    <Link to={`/blog/${edge.node.slug}`}>
                      <h2>{edge.node.title}</h2>

                      <p>{edge.node.snippet}</p>
                    </Link>
                  </li>
                )
              })}
            </ol>
          </div>
        </div>
        <More />
      </div>
    </Layout>
  )
}

export default blog
